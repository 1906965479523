<template>
  <SignIn v-if="!usernameExist" />
  <div class="event-details">

    <div class="information">
      <h1>{{ eventName }}</h1>
      <div class="date-chooser">
        <div class="left" @click="page !== 0 && page--" :class="page === 0 ? 'unactive' : ''">
          <i class="fas fa-angle-left"></i>
        </div>
        <p>{{ eventRange }}</p>
        <div class="right" @click="page !== pageNumbers && page++" :class="page === pageNumbers ? 'unactive' : ''">
          <i class="fas fa-angle-right"></i>
        </div>
      </div>
    </div>
    <div class="button-group">
      <button @click="clearAll" class="drop-shadow">
        <h3>Clear All</h3>
      </button>
      <button @click="refreshPage" class="drop-shadow">
        <h3>Save</h3>
      </button>
      <button @click="logout" class="logout-btn drop-shadow">
        <i class="fas fa-sign-out-alt"></i>
        <span>Start Over</span>
      </button>
    </div>
  </div>

  <div class="content">
    <Calendar :userName="userName" :page="page" />
    <div class="right-information">
      <div class="share-section">
        <div class="url-container">
          <span class="url-label">Copy URL to share: </span>
          <span class="url-display">{{ truncatedUrl }}</span>
          <button class="copy-button" @click="copyUrl">
            <i class="fa-regular fa-copy"></i>
          </button>
          <div v-if="showToast" class="toast">URL copied to clipboard!</div>
        </div>
      </div>
      <Participants />
      <EventDetails />
    </div>
  </div>
</template>

<script>
import Calendar from "../components/Calendar.vue";
import Participants from "../components/Participants";
import EventDetails from "../components/EventDetails";
import SignIn from "../components/SignIn.vue";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { MutationType } from "@/store/mutations";
import { useRouter } from 'vue-router'; // Add this import

export default {
  emits: ["signIn"],
  components: {
    Calendar,
    Participants,
    EventDetails,
    SignIn,
  },
  setup() {
    const store = useStore();
    const router = useRouter(); // Add this
  const page = computed({
    get: () => store.state.currentPage,
    set: (value) => store.commit(MutationType.SetCurrentPage, value)
  });
    const showToast = ref(false);
    const clearAll = () => {
      store.dispatch("REMOVE_SELF_AVAILABILITY");
    };

    const logout = async () => {
      await store.dispatch("LOGOUT_FROM_MICROSOFT");
     
      await router.push('/');
      window.location.reload();
    };
    const copyUrl = () => {
      navigator.clipboard.writeText(window.location.href);
      showToast.value = true;
      setTimeout(() => {
        showToast.value = false;
      }, 2000);
    };

  // Add page watching to handle navigation limits
  watch(page, (newPage) => {
      const maxPages = store.getters.getPageNumbers;
      if (newPage < 0) {
        page.value = 0;
      } else if (newPage > maxPages) {
        page.value = maxPages;
      }
    });


    const refreshPage = () => {
      window.location.reload();
    };


   

    return {
      clearAll,
      showToast,
      logout,
      refreshPage,
      copyUrl,
      page,
      pageNumbers: computed(() => store.getters.getPageNumbers),
      eventRange: computed(() => store.getters.getTopLabel),
      userName: computed(() => store.state.userName),
      eventName: computed(() => store.getters.getEventName),
      usernameExist: computed(() => store.getters.usernameExist),
      truncatedUrl: computed(() => {
        const url = window.location.href;
        return url.length > 40 ? url.substring(0, 40) + '...' : url;
      }),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles.scss";

.unactive {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.box-shadow-2{
  box-shadow: 0 1px 2px rgba(0,0,0,0.15)
}

.event-details {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 120px;
  margin-right: 50px;
  display: flex;
  padding-left: 25%;
  align-items: center;
  justify-content: space-between;
  overflow: show;

  .information {
    display: flex;
    align-items: baseline;

    h1 {
      text-transform: uppercase;
      font-size: 2rem;
      margin-right: 40px;
    }

    .date-chooser {
      display: flex;
      align-items: center;
      color: rgb(99, 99, 99);
      width: 300px;

      p {
        padding: 0 20px;
        font-size: 1.6rem;
      }

      .left,
      .right {
        cursor: pointer;
        font-size: 2rem;
      }
    }
  }

  .button-group {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;

    button {
      padding: 10px;
      border-radius: 10px;
      border: 0;
      background-color: white;
      color: rgb(0, 0, 0);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.1s ease-in-out;
    }

    .url-icon {
      background-color: transparent;
      border-radius: 15px;
      padding: 10px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    }

    .url-icon:hover {
      background-color: darkgrey;
    }


    .url-container {
      display: flex;
      align-items: center;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
      padding-left: 10px;
      width: 100%;


  
    }
  }
}



@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  15% {
    opacity: 1;
    transform: translateY(0);
  }

  85% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}


.right-information {
  width: 450px; // Adjust as needed
  margin-left: 20px;
}

.share-section {
  margin-bottom: 20px;
}

.url-container {
  background-color: white;
  border-radius: 10px;
  padding: 12px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;

  .url-label {
    font-weight: 500;
    color: #333;
    margin-right: 8px;
  }

  .url-display {
    color: #666;
    font-size: 0.9rem;
    flex: 1;
    min-width: 150px;
    word-break: break-all;
  }

  .copy-button {
    background: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: #666;
    border-radius: 6px;
    transition: all 0.2s ease;

    &:hover {
      background-color: #f0f0f0;
      color: #333;
    }

    i {
      font-size: 1.1rem;
    }
  }
}

.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 0.9rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadeInOut 2s ease;
}



.content {
  display: flex;
  justify-content: space-between;
  margin-right: 4em;
  padding-left: 25%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>