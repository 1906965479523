<template>
  <div class="calendar-container">
    <div class="calendar-and-list">
      <div class="calendar-wrapper">
        <div class="outer">
          <div class="timelabels">
            <span v-for="tlabel in timeLabels" :key="tlabel">{{ tlabel }}</span>
          </div>
          <div class="calendar">
            <div class="day" v-for="(day, idx) in result" :key="idx">
              <div class="daylabel">
                <span>{{ getDate(day[0]) }}</span>
                <p>{{ getDay(day[0]) }}</p>
              </div>
              <HourBox :day="day" :idx="idx" :key="availabilityUpdateKey" />
            </div>
          </div>
        </div>
      </div>
      <AvailabilityList :key="availabilityUpdateKey" />
    </div>
  </div>
</template>

<script>
import { ActionTypes } from "@/store/actions";
import { getDate, getDay } from "../utils/utils";
import HourBox from "./HourBox.vue";
import AvailabilityList from "./AvailabilityList.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { computed, toRefs, ref, watchEffect, onMounted } from "vue";

export default {
  components: { HourBox, AvailabilityList },
  props: {
    page: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const store = useStore();
    const { page } = toRefs(props);
    const availabilityUpdateKey = ref(0);
    const loading = ref(true);

    onMounted(async () => {
      if (route.params.id) {
        await store.dispatch(ActionTypes.loadAvailabilities, route.params.id);
        loading.value = false;
      }
    });

    const availability = computed(() => store.getters.getAvailability || {});
    const eventData = computed(() => store.getters.getEventData);
   

    const result = computed(() => {
  if (loading.value || !eventData.value || !eventData.value.selected_dates?.length) {
    return [[]];
  }

  const datesPerPage = 5;
  const startIdx = page.value * datesPerPage;
  const pageDates = eventData.value.selected_dates.slice(startIdx, startIdx + datesPerPage);

  return pageDates.map(dateStr => {
    // Create date in local timezone
    const localDate = new Date(dateStr + 'T00:00:00');
    
    // Get Unix timestamp for start of day in local timezone
    const unixStart = Math.floor(new Date(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      0, 0, 0, 0
    ).getTime() / 1000);
    
    const [startHour] = eventData.value.start_time.split(':').map(Number);
    const [endHour] = eventData.value.end_time.split(':').map(Number);
    
    const slots = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      // Add full hour slot
      slots.push((unixStart + (hour * 3600)).toString());
      // Add half hour slot
      slots.push((unixStart + (hour * 3600) + 1800).toString());
    }
    
    return slots;
  });
});

const timeLabels = computed(() => {
  if (!eventData.value?.start_time || !eventData.value?.end_time) return [];

  const [startHour] = eventData.value.start_time.split(':').map(Number);
  const [endHour] = eventData.value.end_time.split(':').map(Number);
  
  const labels = [];
  for (let hour = startHour; hour <= endHour; hour++) {
    labels.push(`${hour.toString().padStart(2, '0')}:00`);
    labels.push(`${hour.toString().padStart(2, '0')}:30`);
  }
  return labels;
});
    const updateAvailability = (payload) => {
      store.dispatch(ActionTypes.updateAvailability, payload);
      availabilityUpdateKey.value++;
    };

    return {
      updateAvailability,
      availability,
      result,
      getDate,
      getDay,
      timeLabels,
      availabilityUpdateKey,
      loading,
      eventData
    };
  },
};
</script>

<style lang="scss" scoped>


.calendar-container {
 display: flex;
 flex-direction: column;
 margin: 20px;
 gap: 2rem;
 width: 100%;
}

.calendar-and-list {
 display: flex;
 flex-direction: column;
 gap: 2rem;
 min-width: min-content; // Changed to min-content
}
.calendar-wrapper {
  width: 100%;
}


.outer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.calendar {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  background-color: #f7f7f7;
}

.timelabels {
  color: #686868;
  padding-top: 80px;
  padding-bottom: 25px;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 15px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  span {
    font-size: 0.8rem;
  }

  text-align: right;
  white-space: nowrap;
}

.hour-label {
  margin: 0px 5px;
  display: inline-block;
  width: 50px;
}

.day {
  text-align: center;
}

.daylabel {
  color: #686868;
  top: -35px;
  text-align: center;
  align-self: center;
  display: inline-block;
  white-space: normal;
  width: 100%;
  margin: 10px 0;
  user-select: none;  // Add this line
  -webkit-user-select: none;  // For Safari support
  -moz-user-select: none;     // For Firefox support
  -ms-user-select: none;      // For IE/Edge support

  span {
    overflow-x: scroll;
    white-space: nowrap;
    font-size: 1.5rem;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  p {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    margin-top: 5px;
    text-transform: uppercase;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
}

.hours {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(10, 1fr);
}
</style>