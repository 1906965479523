// Hardcoded fallback values for development
const fallbackConfig = {
  AZURE_CLIENT_ID: '954e8da4-e813-4ac1-8b16-29638f71291a',
  AZURE_TENANT_ID: '52e54255-72b0-4649-951a-2de4c170009d',
  REDIRECT_URI: 'https://meet.msuit.ca'
};

const config = {
  AZURE_CLIENT_ID: import.meta.env?.VITE_AZURE_CLIENT_ID || fallbackConfig.AZURE_CLIENT_ID,
  AZURE_TENANT_ID: import.meta.env?.VITE_AZURE_TENANT_ID || fallbackConfig.AZURE_TENANT_ID,
  REDIRECT_URI: import.meta.env?.VITE_REDIRECT_URI || fallbackConfig.REDIRECT_URI
};

// Log configuration for debugging

export default config;