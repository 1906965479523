import { GetterTree } from "vuex";
import { EventData, State, User, AzureUser } from "./state";
import { isEmpty, isNil, chunk } from "lodash";
import { timeLabelGenerator, getLabelTop, getMonths } from "../utils/utils";
import { format, isValid, parseISO } from 'date-fns';

export type Getters = {
  usernameExist(state: State): boolean;
  getUserID(state: State): null | string;
  getEventData(state: State): EventData;
  getAvailability(state: State): { [key: string]: string[] };
  getSplitAvailabilities(state: State, getters: Getters): string[][][] | void;
  getEventName(state: State): string;
  getTimeLabels(state: State): string[];
  getTopLabel(state: State): string;
  getPageNumbers(state: State, getters: Getters): number;
  getParticipantList(state: State): User[];
  getEventDetails(state: State): { created: string; author: string; participants: number };
  getCurrentUser(state: State): AzureUser | null;
};

export const getters: GetterTree<State, State> & Getters = {
  usernameExist(state) {
    return state.userID != null && state.userID.length > 0;
  },
  
  getUserID(state) {
    return state.userID;
  },
  
  getEventData(state) {
    return state.eventData;
  },
  
  getAvailability(state) {
    return state.eventData?.availability || {};
  },
  
  getCurrentUser(state) {
    return state.currentUser;
  },

  getSplitAvailabilities(state) {
    if (isEmpty(state.eventData) || !state.eventData.selected_dates) {
      return [[[]]];
    }

    const availability = state.eventData.availability || {};
    const selectedDates = state.eventData.selected_dates;
    const timestamps = Object.keys(availability).sort((a, b) => parseInt(a) - parseInt(b));

    // Group timestamps by selected dates
    const dayGroups = new Map();
    selectedDates.forEach(dateStr => {
      const dayStart = new Date(dateStr);
      dayStart.setHours(0, 0, 0, 0);
      const dayEnd = new Date(dateStr);
      dayEnd.setHours(23, 59, 59);
      
      const dayTimestamps = timestamps.filter(timestamp => {
        const date = new Date(parseInt(timestamp) * 1000);
        return date >= dayStart && date <= dayEnd;
      });

      if (dayTimestamps.length > 0) {
        dayGroups.set(dateStr, dayTimestamps);
      }
    });

    const days = Array.from(dayGroups.values());
    const NUMBER_OF_DAYS_PER_PAGE = 5;
    return chunk(days, NUMBER_OF_DAYS_PER_PAGE);
  },

  getPageNumbers(state, getters) {
    if (!state.eventData?.selected_dates?.length) return 0;
    return Math.ceil(state.eventData.selected_dates.length / 5) - 1;
  },

  getEventName(state) {
    return state.eventData?.event_name || "";
  },

  getTimeLabels(state) {
    if (isEmpty(state.eventData)) return [];
    return timeLabelGenerator(state.eventData.start_time, state.eventData.end_time);
  },

 getTopLabel(state) {
  if (!state.eventData?.selected_dates?.length) return "";
  
  // Get page from eventData or default to 0
  const datesPerPage = 5;
  const startIdx = state.currentPage * datesPerPage;
  const endIdx = Math.min(startIdx + datesPerPage, state.eventData.selected_dates.length);
  
  const visibleDates = state.eventData.selected_dates
    .slice(startIdx, endIdx)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const firstDate = new Date(visibleDates[0]);
  const lastDate = new Date(visibleDates[visibleDates.length - 1]);
  
  const firstMonth = firstDate.toLocaleString('default', { month: 'long' });
  const lastMonth = lastDate.toLocaleString('default', { month: 'long' });
  
  if (firstMonth === lastMonth) {
    return firstMonth;
  }
  return `${firstMonth} - ${lastMonth}`;
},

  getParticipantList(state) {
    return state.eventData?.users || [];
  },

  getEventDetails(state) {
    if (isEmpty(state.eventData)) {
      return { created: "N/A", author: "N/A", participants: 0 };
    }

    let created = "N/A";
    if (state.eventData.createdAt) {
      const date = parseISO(state.eventData.createdAt);
      created = isValid(date) ? format(date, 'dd MMM yyyy') : "Invalid Date";
    }

    const participants = state.eventData.users?.length || 0;
    const author = participants > 0 ? state.eventData.users[0].name : "unknown";

    return { created, author, participants };
  },
};