import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/MSU.png'


const _hoisted_1 = { class: "backdrop" }
const _hoisted_2 = {
  key: 0,
  class: "signin-form"
}
const _hoisted_3 = { class: "signin-options" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    appear: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, { name: "modal" }, {
          default: _withCtx(() => [
            (_ctx.show)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _cache[6] || (_cache[6] = _createElementVNode("h1", null, "Sign In", -1)),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("button", {
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.signInWithMicrosoft && _ctx.signInWithMicrosoft(...args))),
                      class: "ms-signin-btn",
                      disabled: _ctx.isAuthenticating
                    }, [
                      _cache[4] || (_cache[4] = _createElementVNode("img", {
                        src: _imports_0,
                        alt: "Microsoft",
                        class: "ms-logo"
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.isAuthenticating ? 'Signing in...' : 'Sign in with MSU Microsoft Account'), 1)
                    ], 8, _hoisted_4),
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "divider" }, [
                      _createElementVNode("span", { class: "or" }, "or")
                    ], -1)),
                    _createElementVNode("form", {
                      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.signInAsGuest && _ctx.signInAsGuest(...args)), ["prevent"])),
                      class: "guest-form"
                    }, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.guestName) = $event)),
                        placeholder: "Full Name (Required)",
                        required: "",
                        class: "email-input"
                      }, null, 512), [
                        [_vModelText, _ctx.guestName]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        type: "email",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.guestEmail) = $event)),
                        placeholder: "Enter your email (Required)",
                        required: "",
                        class: "email-input"
                      }, null, 512), [
                        [_vModelText, _ctx.guestEmail]
                      ]),
                      _createElementVNode("button", {
                        type: "submit",
                        class: "guest-btn",
                        disabled: !_ctx.guestEmail && !_ctx.guestName
                      }, " Continue as Guest ", 8, _hoisted_5)
                    ], 32)
                  ]),
                  (_ctx.error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.error), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}