<template>
  <div class="participants">
    <h3>Participants</h3>
    <div v-if="eventData?.users?.length" class="participant-list">
      <div
        v-for="user in eventData.users"
        :key="user.id"
        class="participant"
        :class="{ highlight: styleBindingParticipant(user.id) }"
      >
        {{ user.name }}
      </div>
    </div>
    <div v-else class="no-participants">
      No participants yet
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const eventData = computed(() => store.state.eventData);
    const currentHover = computed(() => store.state.currentHover || []);

    const styleBindingParticipant = (id) => {
      return currentHover.value?.includes(id);
    };

    return {
      eventData,
      styleBindingParticipant
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles.scss";

.participants {
  border-radius: 20px;
  background-color: white;
  width: 100%;
  padding: 10px 20px;
  h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: rgb(99, 99, 99);
    margin-top: 20px;
    margin-left: 10px;
  }
  display: flex;
  flex-direction: column;

  .participant {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 5px;
    transition: all 0.1s ease-in;
    p {
      display: inline;
      margin-left: 10px;
      color: rgb(99, 99, 99);
    }
  }
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 2s ease-in;
}
</style>
