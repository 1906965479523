import { MutationTree } from "vuex";
import { State, EventData, AzureUser } from "./state";
import { User } from "./state";
import { format } from "date-fns";

export enum MutationType {
  FetchAvailability = "FETCH_AVAILABILITY",
  AddAvailability = "ADD_AVAILABILITY",
  RemoveAvailability = "REMOVE_AVAILABILITY",
  AddUserName = "ADD_USERNAME",
  RetrieveUserID = "RETRIEVE_USERID",
  updateHover = "UPDATE_HOVER",
  removeSelfAvailability = "REMOVE_SELF_AVAILABILITY",
  UpdateAvailabilityList = "UPDATE_AVAILABILITY_LIST",
  SetUserID = "SET_USER_ID",
  SetCurrentUser = "SET_CURRENT_USER",
  ClearCurrentUser = "CLEAR_CURRENT_USER",
  SetSelectedDates = "SET_SELECTED_DATES",
    SetCurrentPage = "SET_CURRENT_PAGE"
}
export type Mutations = {
  [MutationType.FetchAvailability](state: State, data: EventData): void;
  [MutationType.AddAvailability](
    state: State,
    content: { unixtime: string; eventID: string }
  ): void;
  [MutationType.SetUserID](state: State, userID: string): void;  // Add this line
  [MutationType.RemoveAvailability](state: State, content: any): void;
  [MutationType.AddUserName](state: State, user: User): void;
  [MutationType.RetrieveUserID](state: State, content: any): void;
  [MutationType.updateHover](state: State, unixtime: string): void;
  [MutationType.UpdateAvailabilityList](
    state: State,
    payload: { unixtime: string; eventID: string; action: 'ADD' | 'REMOVE' }
  ): void;
  [MutationType.SetCurrentUser](state: State, user: AzureUser): void;
  [MutationType.ClearCurrentUser](state: State, payload?: void): void;  
  [MutationType.SetSelectedDates](state: State, dates: string[]): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationType.FetchAvailability](state, data) {
    state.eventData = data;
  },

  [MutationType.SetSelectedDates](state, dates) {
    state.selectedDates = dates;
    if (state.eventData) {
      state.eventData.selected_dates = dates;
    }
  },

    
  [MutationType.SetCurrentPage](state, page: number) {
    state.currentPage = page;
  },

  [MutationType.SetCurrentUser](state, user) {
    state.currentUser = user;
    state.userID = user.id;
  },
  [MutationType.ClearCurrentUser](state) {
    state.currentUser = null;
    state.userID = "";
  },

  [MutationType.AddAvailability](state, content) {
    const { unixtime } = content;
    const userID = state.userID;

    if (userID && state.eventData.availability[unixtime]) {
      if (!state.eventData.availability[unixtime].includes(userID)) {
        state.eventData.availability[unixtime].push(userID);
      }
    }
  
  },

  [MutationType.RemoveAvailability](state, content) {
    const { unixtime } = content;
    const userID = state.userID;
  
    if (userID && state.eventData.availability[unixtime]) {
      state.eventData.availability[unixtime] = state.eventData.availability[unixtime].filter((val: string) => val !== userID);
    }
   
  },

  [MutationType.UpdateAvailabilityList](state, payload) {
    const { unixtime, action } = payload;
    const userID = state.userID;
    
    // Validate the timestamp
    const date = new Date(parseInt(unixtime) * 1000);
    
    
    if (!state.eventData.availability[unixtime]) {
      state.eventData.availability[unixtime] = [];
    }
  
    if (action === 'ADD') {
      if (!state.eventData.availability[unixtime].includes(userID)) {
        state.eventData.availability[unixtime].push(userID);
      }
    } else if (action === 'REMOVE') {
      state.eventData.availability[unixtime] = state.eventData.availability[unixtime].filter(id => id !== userID);
    }
    

  },
  [MutationType.AddUserName](state, user) {
    if (!state.eventData.users) {
      state.eventData.users = [user];
    } else {
      // Check if user already exists by id or microsoftId
      const existingUser = state.eventData.users.find(existingUser => 
        existingUser.id === user.id || 
        ('microsoftId' in existingUser && 'microsoftId' in user && existingUser.microsoftId === user.microsoftId)
      );
      
      if (!existingUser) {
        state.eventData.users.push(user);
      } else {
        // Update existing user's information if needed
        const index = state.eventData.users.findIndex(u => u.id === existingUser.id);
        if (index !== -1) {
          state.eventData.users[index] = {
            ...state.eventData.users[index],
            ...user
          };
        }
      }
    }
    state.userID = user.id;
},
  [MutationType.SetUserID](state, userID: string) {
 
    state.userID = userID;
  },
  [MutationType.RetrieveUserID](state, eventID) {
    const userStorage = localStorage.getItem(eventID);
    state.userID = userStorage || "";
  },
  [MutationType.updateHover](state, unixtime) {
    if (unixtime === "MouseOut") {
      state.currentHover = state.eventData.users.map((user) => user.id);
    } else {
      state.currentHover = state.eventData.availability[unixtime];
    }
  },
  [MutationType.removeSelfAvailability](state) {
    for (const unixtime of Object.keys(state.eventData.availability)) {
      state.eventData.availability[unixtime] = state.eventData.availability[
        unixtime
      ].filter((userid) => userid !== state.userID);
    }
  },
};