<template>
  <div class="home">
    <div class="content-wrapper">
      <div class="information">
        <span data-test="landing">Bring your <br />team<br />together</span>
        <router-link :to="{ name: 'newevent' }">Get Started</router-link>
      </div>

      <div class="animation-container">
        <lottie-player
          id="lottie"
          src="https://assets6.lottiefiles.com/packages/lf20_pyawb2b1.json"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 500px;
  width: 100%;
  padding: 2rem;
}

.content-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  min-height: 500px;

  @media (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
    gap: 4rem;
  }
}

.information {
  flex: 1;
  display: flex;
  flex-direction: column;
  
  span {
    font-size: clamp(3rem, 5vw, 5.2rem);
    font-weight: bold;
    line-height: 1.2;
  }

  a {
    color: #494949;
    margin-top: 30px;
    padding: 10px 10px;
    width: 200px;
    font-size: 20px;
    border-radius: 30px;
    border: 0;
    background-color: #f6c467;
    text-decoration: none;
    text-align: center;
    transition: all 0.2s ease;

    @media (max-width: 1024px) {
      margin: 30px auto 0;
    }
    
    &:hover {
      background-color: #f7ac21;
      transform: scale(1.02);
    }
  }
}

.animation-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#lottie {
  width: min(600px, 100%);
  height: min(600px, 100%);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-to {
  transform: translateX(-80%);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s ease-in;
}

.fade-leave-active {
  transition: all 0.4s linear;
}
</style>