<template>
  <transition name="fade" appear>
    <div class="backdrop">
      <transition name="modal">
        <div class="signin-form" v-if="show">
          <h1>Sign In</h1>
          <div class="signin-options">
            <button @click="signInWithMicrosoft" class="ms-signin-btn" :disabled="isAuthenticating">
              <img src="../assets/MSU.png" alt="Microsoft" class="ms-logo" />
              {{ isAuthenticating ? 'Signing in...' : 'Sign in with MSU Microsoft Account' }}
            </button>
            
            <div class="divider">
              <span class="or">or</span>
            </div>

            <form @submit.prevent="signInAsGuest" class="guest-form">
              <input 
                type="text" 
                v-model="guestName" 
                placeholder="Full Name (Required)"
                required
                class="email-input"
              />
              <input 
                type="email" 
                v-model="guestEmail" 
                placeholder="Enter your email (Required)"
                required
                class="email-input"
              />
              <button type="submit" class="guest-btn" :disabled="!guestEmail && !guestName">
                Continue as Guest
              </button>
            </form>
          </div>
          <div v-if="error" class="error">{{ error }}</div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ActionTypes } from "../store/actions";
import { AuthenticationResult } from "@azure/msal-browser";
import { loginRequest } from "../auth/msalConfig";
import MSALInstance from "../auth/msal-instance";
import type { Store } from 'vuex';
import type { State } from '../store/state';
import { msalCacheUtils } from "../auth/msalConfig";  // Add this import

export default defineComponent({
  name: "SignIn",
  setup() {
    const store = useStore<State>();
    const route = useRoute();
    const router = useRouter();
    const show = ref(false);
    const error = ref("");
    const guestEmail = ref("");
    const guestName = ref("");
    const isAuthenticating = ref(false);


    const signInAsGuest = async () => {
      try {
        await store.dispatch(ActionTypes.loginAsGuest, {
          guestName : guestName.value,
          email: guestEmail.value,
          eventID: route.params.id
        });
        router.push(`/event/${route.params.id}`);
      } catch (err) {
        error.value = "Failed to sign in as guest. Please try again.";
      }
    };

    onMounted(async () => {
      show.value = true;
      await checkExistingAuth();
    });

    const checkExistingAuth = async (): Promise<void> => {
  try {
    const msalInstance = await MSALInstance.getInstance();
    const accounts = msalInstance.getAllAccounts();
    
    if (accounts.length > 0) {
      // Get active account
      const activeAccount = accounts[0];
      
      // Try silent token acquisition
      const silentRequest = {
        ...loginRequest,
        account: activeAccount,
        forceRefresh: false
      };
      
      const response = await msalInstance.acquireTokenSilent(silentRequest);
      await handleLoginSuccess({ 
        account: activeAccount,
        accessToken: response.accessToken
      } as AuthenticationResult);
    }
  } catch (err) {
    console.error("Error during auto-login:", err);
    // Clear any stale data
    msalCacheUtils.clearCache();
  }
};

    const handleLoginSuccess = async (loginResponse: AuthenticationResult): Promise<void> => {
      try {
        await store.dispatch(ActionTypes.loginWithMicrosoft, {
          microsoftUser: loginResponse,
          eventID: route.params.id
        });
        
        // Redirect to appropriate page after login
        router.push(`/event/${route.params.id}`);
      } catch (err) {
        console.error("Error handling login:", err);
        error.value = "Failed to complete sign in. Please try again.";
      } finally {
        isAuthenticating.value = false;
      }
    };

    const signInWithMicrosoft = async (): Promise<void> => {
      try {
        isAuthenticating.value = true;
        const msalInstance = await MSALInstance.getInstance();
        const loginResponse = await msalInstance.loginPopup(loginRequest);
        await handleLoginSuccess(loginResponse);
      } catch (err) {
        console.error("Error during sign in:", err);
        error.value = "Failed to sign in. Please try again.";
        isAuthenticating.value = false;
      }
    };

    return {
      guestName,
      guestEmail, 
      show, 
      signInAsGuest,
      error, 
      signInWithMicrosoft,
      isAuthenticating
    };
  },
});
</script>

<style lang="scss" scoped>
.backdrop {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
h1 {
  font-size: 20px;
  text-align: center;
  width: auto;
  font-weight: bold;
  margin-bottom: 15px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}

.ms-signin-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  &:not(:disabled):hover {
    background-color: #f3f3f3;
  }
}

.ms-logo {
  width: 20px;
  height: 20px;
}

.error {
  color: red;
  margin-top: 10px;
  text-align: center;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s ease-in;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.4s ease-in;
}
// Add to existing styles
.signin-form {
  width: 400px; // Increased width
}

.signin-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.divider {
  text-align: center;
  position: relative;
  margin: 15px 0;
  
  &::before, &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background-color: #e0e0e0;
  }
  
  &::before { left: 0; }
  &::after { right: 0; }
  
  span {
    background-color: white;
    padding: 0 10px;
    color: #666;
    font-size: larger;
    font-weight: bold;
  }
}

.guest-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.email-input {
  padding: 10px;
  border: 1px solid #8c8c8c;
  border-radius: 4px;

}

.guest-btn {
  padding: 10px;
  background-color: #008cba;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  &:not(:disabled):hover {
    background-color: #007ba4;
  }
}
</style>