<template>
  <transition>
    <div class="container">
      <form @submit.prevent="submitHandler">
        <div class="row">
          <label for="event-name">Event Name</label>
          <input data-test="eventname" type="text" v-model="name" placeholder="Event Name" />
        </div>
        <p v-if="error.name" class="error">{{ error.name }}</p>

        <!-- Quick Select Buttons -->
        <div class="quick-select row">
          <label>Quick Select</label>
          <div class="buttons-group">
            <button type="button" @click="selectToday" class="quick-btn">
              Today
            </button>
            <button type="button" @click="selectCurrentWeekWorkdays" class="quick-btn">
              This Week (Mon-Fri)
            </button>
            <button type="button" @click="selectNextWeekWorkdays" class="quick-btn">
              Next Week (Mon-Fri)
            </button>
          </div>
        </div>

        <!-- Month Navigation -->
        <div class="month-navigation row">
          <button type="button" @click="previousMonth" class="nav-btn">&lt; Previous</button>
          <span class="current-month">{{ currentMonthDisplay }}</span>
          <button type="button" @click="nextMonth" class="nav-btn">Next &gt;</button>
        </div>

        <!-- Calendar Days Selection -->
        <div class="calendar-days">
          <div class="weekday-headers">
            <span v-for="day in weekDays" :key="day" :class="{ 'weekend': day === 'Sun' || day === 'Sat' }">
              {{ day }}
            </span>
          </div>
          <div class="days-grid">
            <button v-for="(day, index) in displayedDays" :key="index" type="button" @click="toggleDay(day)" :class="[
              'day-btn',
              {
                'selected': day.isSelected,
                'other-month': day.isOtherMonth,
                'weekend': day.isWeekend
              }
            ]" :disabled="day.isPast">
              <div class="day-number">{{ day.date.getDate() }}</div>
            </button>
          </div>
        </div>
        <p v-if="error.days" class="error">{{ error.days }}</p>

        <!-- Selected Dates Display -->
        <div class="selected-dates row" v-if="selectedDatesDisplay.length">
          <label>Selected Dates:</label>
          <div class="selected-dates-container">
            <div class="selected-dates-list">
              <div v-for="(dateGroup, index) in groupedSelectedDates" :key="index" class="selected-dates-row">
                {{ dateGroup.join(', ') }}
              </div>
            </div>
          </div>
        </div>

        <!-- Time Selection -->
        <div class="row">
          <label for="stime">Start Time</label>
          <input type="time" name="stime" v-model="stime" />
        </div>
        <p v-if="error.stime" class="error">{{ error.stime }}</p>

        <div class="row">
          <label for="etime">End Time</label>
          <input type="time" name="etime" v-model="etime" />
        </div>
        <p v-if="error.etime" class="error">{{ error.etime }}</p>
      </form>
      <button type="submit" @click="submitHandler">Submit</button>
    </div>
  </transition>
</template>

<script>
import { instance } from "../api";
import { MutationType } from '../store/mutations';
export default {
  data() {
    return {
      name: "",
      stime: "09:00",
      etime: "17:00",
      currentDate: new Date(),
      selectedDays: new Set(),
      displayedDays: [],
      weekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      error: {
        name: "",
        days: "",
        stime: "",
        etime: "",
      },
    };
  },
  computed: {
    currentMonthDisplay() {
      return this.currentDate.toLocaleDateString('en-US', {
        month: 'long',
        year: 'numeric'
      });
    },
    selectedDatesDisplay() {
      return Array.from(this.selectedDays)
        .sort((a, b) => new Date(a) - new Date(b))
        .map(date => new Date(date).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          weekday: 'short'
        }));
    },
    groupedSelectedDates() {
      const dates = this.selectedDatesDisplay;
      const groups = [];
      for (let i = 0; i < dates.length; i += 4) {
        groups.push(dates.slice(i, i + 4));
      }
      return groups;
    }
  },
  mounted() {
    this.initializeCalendar();
  },
  methods: {
    initializeCalendar() {
      this.generateCalendarDays();
    },
    generateCalendarDays() {
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth();
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);
      const days = [];

      // Add days from previous month
      const firstDayOfWeek = firstDay.getDay();
      for (let i = firstDayOfWeek - 1; i >= 0; i--) {
        const date = new Date(year, month, -i);
        days.push(this.createDayObject(date, true));
      }

      // Add days of current month
      for (let date = 1; date <= lastDay.getDate(); date++) {
        const currentDate = new Date(year, month, date);
        days.push(this.createDayObject(currentDate, false));
      }

      // Add days from next month
      const remainingDays = 42 - days.length; // 6 rows * 7 days
      for (let i = 1; i <= remainingDays; i++) {
        const date = new Date(year, month + 1, i);
        days.push(this.createDayObject(date, true));
      }

      this.displayedDays = days;
    },
    createDayObject(date, isOtherMonth) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const isWeekend = date.getDay() === 0 || date.getDay() === 6;

      return {
        date: new Date(date),
        isOtherMonth,
        isWeekend,
        isSelected: this.selectedDays.has(date.toDateString()),
        isPast: date < today
      };
    },
    toggleDay(day) {
      if (day.isPast) return;  // Removed || day.isWeekend

      const dateStr = day.date.toDateString();
      if (this.selectedDays.has(dateStr)) {
        this.selectedDays.delete(dateStr);
      } else {
        this.selectedDays.add(dateStr);
      }
      this.generateCalendarDays();
    },
    selectToday() {
      const today = new Date();
      if (today.getDay() !== 0 && today.getDay() !== 6) { // Don't select if weekend
        this.selectedDays.clear();
        this.selectedDays.add(today.toDateString());
        this.generateCalendarDays();
      }
    },
    getWeekDates(startOfWeek) {
      const dates = new Set();
      const currentDay = new Date(startOfWeek);

      // Get Monday-Friday of the specified week
      for (let i = 0; i < 5; i++) {
        if (currentDay.getDay() !== 0 && currentDay.getDay() !== 6) {
          dates.add(new Date(currentDay).toDateString());
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return dates;
    },
    selectCurrentWeekWorkdays() {
      const today = new Date();
      const monday = new Date(today);
      const dayOfWeek = today.getDay();

      // Calculate Monday of current week
      monday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));

      this.selectedDays = this.getWeekDates(monday);
      this.generateCalendarDays();
    },
    selectNextWeekWorkdays() {
      const today = new Date();
      const nextMonday = new Date(today);
      const dayOfWeek = today.getDay();

      // Calculate next Monday
      nextMonday.setDate(today.getDate() + (8 - dayOfWeek));

      this.selectedDays = this.getWeekDates(nextMonday);
      this.generateCalendarDays();
    },
    previousMonth() {
      const newDate = new Date(this.currentDate);
      newDate.setMonth(newDate.getMonth() - 1);
      this.currentDate = newDate;
      this.generateCalendarDays();
    },
    nextMonth() {
      const newDate = new Date(this.currentDate);
      newDate.setMonth(newDate.getMonth() + 1);
      this.currentDate = newDate;
      this.generateCalendarDays();
    },
    async submitHandler() {
  if (!this.validateForm()) return;

  const selectedDates = Array.from(this.selectedDays)
    .map(dateStr => new Date(dateStr))
    .sort((a, b) => a - b);

  const formattedDates = selectedDates.map(date => 
    date.toISOString().split('T')[0]
  );

  // Store selected dates in Vuex
  this.$store.commit(MutationType.SetSelectedDates, formattedDates);

  const eventDetails = {
    event_name: this.name,
    start_date: formattedDates[0],
    end_date: formattedDates[formattedDates.length - 1],
    start_time: this.stime,
    end_time: this.etime,
    selected_dates: formattedDates
  };

  try {
    const postResponse = await instance.post("/add", eventDetails);
    const eventID = postResponse.data;
    this.clearForm();
    this.$router.push({ name: "event", params: { id: eventID } });
  } catch (error) {
    console.error("Error submitting event:", error);
    this.error.submit = "Failed to create event. Please try again.";
  }
},
    validateForm() {
      let isValid = true;
      this.error = {
        name: "",
        days: "",
        stime: "",
        etime: "",
      };

      if (!this.name.trim()) {
        this.error.name = "Event name is required";
        isValid = false;
      }

      if (this.selectedDays.size === 0) {
        this.error.days = "Please select at least one day";
        isValid = false;
      }

      if (!this.stime) {
        this.error.stime = "Start time is required";
        isValid = false;
      }

      if (!this.etime) {
        this.error.etime = "End time is required";
        isValid = false;
      }

      if (this.stime && this.etime && this.stime >= this.etime) {
        this.error.etime = "End time must be after start time";
        isValid = false;
      }

      return isValid;
    },
    clearForm() {
      this.name = "";
      this.selectedDays.clear();
      this.stime = "09:00";
      this.etime = "17:00";
      this.generateCalendarDays();
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 45%;
  color: white;
  margin: 20px auto;
  background-color: #247ba0;
  padding: 30px 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button[type="submit"] {
    padding: 10px 30px;
    margin-top: 30px;
    color: #494949;
    font-size: 20px;
    border-radius: 30px;
    border: 0;
    background-color: #f6c467;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: #f7ac21;
      transform: scale(1.02);
    }
  }
}

.row {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.quick-select {
  flex-direction: column;
  align-items: flex-start;

  .buttons-group {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
  }
}

.quick-btn {
  padding: 8px 16px;
  background-color: #f6c467;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f7ac21;
    transform: scale(1.02);
  }
}

.calendar-days {
  margin-top: 20px;
  width: 100%;

  .weekday-headers {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;

    .weekend {
      color: #179107;
    }
  }

  .days-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
}

.day-btn {
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &.selected {
    background-color: #f6c467;
    color: #494949;
  }

  &.other-month {
    opacity: 0.5;
  }

  
  &.weekend {
  background-color: #f0f0f0;
  color: #000000;
  
  &:hover:not(:disabled) {
    transform: scale(1.05);
  }
  
  &.selected {
    background-color: #f6c467;
    color: #494949;
  }
}

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    transform: scale(1.05);
  }
}

.month-navigation {
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  .nav-btn {
    padding: 5px 15px;
    background-color: #f6c467;
    border: none;
    border-radius: 15px;
    cursor: pointer;

    &:hover {
      background-color: #f7ac21;
    }
  }

  .current-month {
    font-size: 1.2em;
    font-weight: bold;
  }
}

.selected-dates {
  flex-direction: column;
  align-items: flex-start;

  .selected-dates-container {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #f6c467;
      border-radius: 4px;
    }
  }

  .selected-dates-list {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 10px;
  }

  .selected-dates-row {
    padding: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &:last-child {
      border-bottom: none;
    }
  }
}

.error {
  color: #ff6b6b;
  margin-top: 5px;
  font-size: 0.9em;
}

input {
  width: 250px;
  padding: 10px 10px;
  border-radius: 15px;
  border: 0;
  font-family: "Poppins", sans-serif;

  &:focus {
    outline: none;
  }

  &[type="text"]::placeholder {
    color: rgb(194, 194, 194);
  }
}

.fade-enter-from {
  transform: translateX(200%);
}

.fade-leave-to {
  transform: translateX(-100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s linear;
}
</style>