<template>
  <transition name="fade" appear>
    <div class="event-deets drop-shadow">
      <h3>Event Details</h3>
      <table class="detail-list">
        <tr>
          <th>Created On</th>
          <td>{{ $store.getters.getEventDetails.created }}</td>
        </tr>
        <tr>
          <th>Author</th>
          <td>{{ $store.getters.getEventDetails.author }}</td>
        </tr>
        <tr>
          <th>Participants</th>
          <td>{{ $store.getters.getEventDetails.participants }}</td>
        </tr>
        <tr>
          <th>Time Zone</th>
          <td>Eastern Standard Time</td>
        </tr>
      </table>

      <h3 class="top-dates">Top 3 Dates/Times</h3>
      <span class="hover-text">Hover over time slot to view participants</span>
      <div class="top-slots">
        <div v-for="slot in topTimeSlots" :key="slot.timestamp" class="slot"
          @mouseenter="handleMouseEnter(slot.timestamp)" @mouseleave="handleMouseLeave">
          <div class="slot-header">
            <span class="date">{{ formatDate(slot.timestamp) }}</span>
            <span class="time">{{ formatTime(slot.timestamp) }}</span>
          </div>
          <div class="participants-count">
            {{ slot.users.length }} participants
          </div>
          <div class="names" :class="{ show: hoveredSlot === slot.timestamp }">
            {{ formatUsers(slot.users) }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
// EventDetails.vue
<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { format, fromUnixTime } from 'date-fns';

export default {
  name: 'EventDetails',
  setup() {
    const store = useStore();
    const hoveredSlot = ref(null);

    const formatDate = (timestamp) => {
      return format(fromUnixTime(parseInt(timestamp)), 'EEE, MMM d');
    };

    const formatTime = (timestamp) => {
      return format(fromUnixTime(parseInt(timestamp)), 'h:mm a');
    };

    const formatUsers = (userIds) => {
      const eventData = store.state.eventData;
      if (!eventData?.users) return '';
      return userIds
        .map(id => eventData.users.find(u => u.id === id)?.name || id)
        .join(', ');
    };

    const topTimeSlots = computed(() => {
      const eventData = store.state.eventData;
      if (!eventData?.availability) return [];

      // Get all slots with users
      const slots = Object.entries(eventData.availability)
        .map(([timestamp, users]) => ({
          timestamp,
          users: users || [],
          date: formatDate(timestamp),
          time: formatTime(timestamp)
        }))
        .filter(slot => slot.users.length > 0)
        .sort((a, b) => {
          // Sort by number of users first (descending)
          if (b.users.length !== a.users.length) {
            return b.users.length - a.users.length;
          }
          // Then by timestamp (ascending)
          return parseInt(a.timestamp) - parseInt(b.timestamp);
        })
        .slice(0, 3);  // Get top 3

      return slots;
    });

    const handleMouseEnter = (timestamp) => {
      hoveredSlot.value = timestamp;
    };

    const handleMouseLeave = () => {
      hoveredSlot.value = null;
    };

    return {
      topTimeSlots,
      formatDate,
      formatTime,
      formatUsers,
      hoveredSlot,
      handleMouseEnter,
      handleMouseLeave
    };
  }
};
</script>


<style lang="scss" scoped>
.event-deets {
  margin-top: 20px;
  padding: 10px 20px;
  width: 100%;
  border-radius: 20px;
  background-color: white;

  h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: rgb(99, 99, 99);
    margin-top: 20px;
    margin-left: 10px;
  }
}

.detail-list {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;

  tr {
    color: rgb(99, 99, 99);
    font-size: 12px;

    th {
      font-weight: normal;
      text-transform: uppercase;
      text-align: left;
    }

    td {
      text-align: right;
    }
  }
}

.hover-text{
  font-size: 13px;
  color: #66666675;
}

.top-dates {
  margin-top: 30px;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.top-slots {
  margin: 10px;
}

.slot {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 10px;
  cursor: pointer;

  .slot-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    color: #2c3e50;
    font-weight: bold;
  }

  .participants-count {
    color: #4CAF50;
    font-size: 0.9em;
    margin-bottom: 4px;
  }

  .names {
    color: #666;
    font-size: 0.9em;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;

    &.show {
      height: auto;
    }
  }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 2s ease-in;
}
</style>
