export interface EventData {
  users: User[];
  id: string;  // Changed from _id to id
  event_name: string;
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  availability: { [key: string]: string[] };
  createdAt: string;
  updatedAt: string;
  selected_dates?: string[];  
  currentHover: string[];
  currentPage: number; // Add this line

}

export interface User {
  id: string;
  name: string;
}

export interface AzureUser extends User {
  email: string;
  microsoftId?: string;
  username: string;
}
export interface State {
  eventData: EventData;
  userID: string;  // Changed from "" to string
  currentHover: string[];
  currentUser: AzureUser | null;  // Add this
  selectedDates: string[];  
  currentPage: number; // Add this line
}

export const state: State = {
  eventData: {} as EventData,
  userID: "",
  currentHover: [],
  currentUser: null, 
  selectedDates: [],
  currentPage: 0 
};
