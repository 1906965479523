// src/auth/msal-instance.ts
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./msalConfig";

class MSALInstance {
  private static instance: PublicClientApplication | null = null;
  private static initializationPromise: Promise<void> | null = null;

  public static async getInstance(): Promise<PublicClientApplication> {
    if (!this.instance) {
      this.instance = new PublicClientApplication(msalConfig);
      
      if (!this.initializationPromise) {
        this.initializationPromise = this.instance.initialize();
      }
      
      await this.initializationPromise;
    }
    
    return this.instance;
  }
}

export default MSALInstance;