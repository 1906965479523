import { Configuration, CacheOptions } from "@azure/msal-browser";
import config from './config';

// Validate environment variables
const clientId = config.AZURE_CLIENT_ID;
const tenantId = config.AZURE_TENANT_ID;

if (!clientId || !tenantId) {
  throw new Error(
    `Missing required configuration: 
     ${!clientId ? 'AZURE_CLIENT_ID' : ''} 
     ${!tenantId ? 'AZURE_TENANT_ID' : ''}`
  );
}

// Enhanced cache options for better persistence
const cacheOptions: CacheOptions = {
  cacheLocation: "localStorage", // Changed from sessionStorage
  storeAuthStateInCookie: true,
  secureCookies: true
};

export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: config.REDIRECT_URI,
    postLogoutRedirectUri: config.REDIRECT_URI,
    navigateToLoginRequestUrl: true,
    // Add these configurations for better token handling
    protocolMode: "AAD", // Explicitly set to Azure AD mode
  },
  cache: cacheOptions,
  system: {
    allowNativeBroker: true, // Enable native broker support
    windowHashTimeout: 9000, // Increase timeout for slower connections
    iframeHashTimeout: 9000,
    loadFrameTimeout: 9000
  }
};

// Enhanced login request with additional scopes
export const loginRequest = {
  scopes: [
    "User.Read",
    "profile",
    "email",
    "offline_access", // Add offline_access for refresh tokens
    "openid"
  ],
  prompt: "select_account" // Forces account selection
};

// Separate token request for API access
export const tokenRequest = {
  scopes: [
    "User.Read",
    "profile",
    "email",
    "offline_access",
    "openid"
  ]
};

// Add token refresh configuration
export const silentRequest = {
  scopes: ["User.Read", "profile", "email"],
  forceRefresh: false // Will use cached token if available
};

// Add account management helpers
export const msalCacheUtils = {
  clearCache: () => {
    localStorage.clear();
    sessionStorage.clear();
    // Clear cookies if needed
    document.cookie.split(";").forEach(cookie => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
    });
  },
  
  // Helper to check if user is authenticated
  isAuthenticated: () => {
    const accounts = localStorage.getItem('msal.account.keys');
    return !!accounts && accounts.length > 0;
  }
};