<template>
  <div
    class="hour"
    :style="styleBinding(availability[hour])"
    :class="[
      idx === 0 ? 'first' : '',
      availability[hour]?.includes(userID) ? 'selected' : '',
    ]"
    v-for="hour in day"
    :key="hour"
    @mousedown="clickHandler"
    @mousemove.prevent="dragHandler"
    @mouseover="hoverHandler"
    @mouseout="mouseoutHandler"
    :id="hour"
  ></div>
</template>

<script>
import { useStore } from "vuex";
import { computed, watch } from "vue";
import { useRoute } from "vue-router";
import { ActionTypes } from "../store/actions";
import { fromUnixTime } from 'date-fns';

export default {
  props: { 
    day: {type: Array, required: true}, 
    idx: {type: Number, required: true} 
  },
  setup(props) {
    let action = "ADD";
    const store = useStore();
    const route = useRoute();
    const userID = computed(() => store.getters.getUserID);
    const eventData = computed(() => store.getters.getEventData);
    const availability = computed(() => store.getters.getAvailability || {});

    const isValidHour = (timestamp) => {
      return timestamp && !isNaN(parseInt(timestamp));
    };

    const ensureLocalTimestamp = (timestamp) => {
      const date = fromUnixTime(parseInt(timestamp));
      return Math.floor(new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes()
      ).getTime() / 1000).toString();
    };

    const hoverHandler = (event) => {
      const localTimestamp = ensureLocalTimestamp(event.target.id);
      store.dispatch(ActionTypes.updateHover, localTimestamp);
    };

    const mouseoutHandler = () => {
      store.dispatch(ActionTypes.updateHover, "MouseOut");
    };

    const clickHandler = (event) => {
      event.target.classList.value.includes("selected")
        ? (action = "REMOVE")
        : (action = "ADD");
      UPDATE_AVAILABILITY(action, event);
    };

    const dragHandler = (event) => {
      let mouseClickedDown = event.buttons === 1;
      if (mouseClickedDown) {
        UPDATE_AVAILABILITY(action, event);
      }
    };
    
    const UPDATE_AVAILABILITY = async (action, event) => {
      try {
        const timestamp = event.target.id;
        if (!isValidHour(timestamp)) return;

        const localTimestamp = ensureLocalTimestamp(timestamp);
        
        await store.dispatch(ActionTypes.updateAvailability, {
          unixtime: localTimestamp,
          eventID: route.params.id,
          action,
          userID: userID.value
        });
      } catch (error) {
        console.error('Failed to update availability:', error);
      }
    };

    const styleBinding = (userIds = []) => {
      const degree = Array.isArray(userIds) ? userIds.length : 0;
      return {
        "background-color": `hsl(157, 59%, ${100 - degree * 10}%)`,
        border: degree === 0 ? "solid 0.1px rgb(230, 230, 230)" : `solid 0.1px hsl(157, 59%, ${90 - degree * 10}%)`,
        "border-top": "none",
        "border-left": "none",
      };
    };

    return {
      dragHandler,
      clickHandler,
      hoverHandler,
      mouseoutHandler,
      styleBinding,
      availability: computed(() => store.getters.getAvailability),
      userID,
    };
  },
};
</script>

<style lang="scss" scoped>
.hour {
  width: 7rem;
  height: 18px;
  border: solid 0.1px rgb(230, 230, 230);
  background-color: white;
  border-top: none;
  border-left: none;
  &:nth-child(2) {
    border-top: solid 0.1px rgb(230, 230, 230) !important;
  }
}

.first {
  border-left: solid 0.1px rgb(230, 230, 230) !important;
}

.selected {
  background-color: #4CAF50 !important;
  border-color: #45a049 !important;
}
</style>