// AvailabilityList.vue
<template>
  <div class="availability-list">
    <h3>Availability Summary</h3>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <div v-else-if="groupedAvailability.length" class="availability-content">
      <div v-for="group in groupedAvailability" :key="group.day" class="day-group">
        <h4 class="day-header">{{ group.day }}</h4>
        <div v-for="slot in group.slots" :key="slot.timestamp" class="time-slot">
          <div class="participants">
            <span class="participant-count">{{ slot.users.length }}</span>
            <span class="time">{{ slot.time }}</span>
          </div>
          <div class="names">{{ slot.names }}</div>
        </div>
      </div>
    </div>
    <div v-else class="no-data">No availability data yet</div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { format, fromUnixTime } from 'date-fns';

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = ref(true);
    const error = ref(null);

    const eventData = computed(() => store.state.eventData);

    onMounted(async () => {
      try {
        await store.dispatch('loadAvailabilities', route.params.id);
        loading.value = false;
      } catch (err) {
        error.value = err.message;
        loading.value = false;
      }
    });

    const formatTime = (timestamp) => {
      try {
        const date = fromUnixTime(parseInt(timestamp));
        return format(date, 'h:mm a');
      } catch (error) {
        console.error('Error formatting time:', error);
        return 'Invalid Time';
      }
    };

    const formatDate = (timestamp) => {
      try {
        const date = fromUnixTime(parseInt(timestamp));
        return format(date, 'EEEE, MMMM d, yyyy');
      } catch (error) {
        console.error('Error formatting date:', error);
        return 'Invalid Date';
      }
    };

    const getUserNames = (userIds) => {
      if (!eventData.value?.users || !userIds) return '';
      return userIds
        .map(id => eventData.value.users.find(u => u.id === id)?.name || id)
        .join(', ');
    };

    const groupedAvailability = computed(() => {
      if (!eventData.value?.availability) return [];

      const groups = {};
      
      // Filter and sort all timestamps with users
      const availableSlots = Object.entries(eventData.value.availability)
        .filter(([_, users]) => users && users.length > 0)
        .map(([timestamp, users]) => ({
          timestamp,
          users,
          date: formatDate(timestamp),
          time: formatTime(timestamp),
          names: getUserNames(users)
        }))
        .sort((a, b) => parseInt(a.timestamp) - parseInt(b.timestamp));

      // Group by date
      availableSlots.forEach(slot => {
        if (!groups[slot.date]) {
          groups[slot.date] = {
            day: slot.date,
            slots: []
          };
        }
        groups[slot.date].slots.push(slot);
      });

      // Sort groups by date
      return Object.values(groups)
        .sort((a, b) => new Date(a.day) - new Date(b.day));
    });

    return {
      loading,
      error,
      groupedAvailability
    };
  }
};
</script>


<style scoped>
.availability-list {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 20px;
  max-height: calc(40vh); /* Full viewport height minus margins */
  overflow-y: auto;
  position: sticky;
  top: 20px;

  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 20px;
}

h3 {
  margin-top: 0;
  color: #2c3e50;
  margin-bottom: 20px;
}

.day-group {
  margin-bottom: 20px;
}

.day-header {
  color: #2c3e50;
  border-bottom: 2px solid #eee;
  padding-bottom: 8px;
  margin-bottom: 12px;
}

.time-slot {
  padding: 10px;
  margin-bottom: 8px;
  background-color: #f8f9fa;
  border-radius: 6px;
}

.participants {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.participant-count {
  background-color: #4CAF50;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 0.9em;
}

.time {
  font-weight: bold;
  color: #2c3e50;
}

.names {
  color: #666;
  font-size: 0.9em;
  margin-left: 34px;
}

.loading, .error, .no-data {
  text-align: center;
  padding: 20px;
  color: #666;
}

.error {
  color: #dc3545;
}
</style>