import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationType } from "./mutations";
import { State, AzureUser } from "./state";
import { instance } from "../api";
import { AxiosError } from 'axios';  // Add this import



export enum ActionTypes {
  loadAvailabilities = "LOAD_AVAILABILITIES",
  addEvent = "ADD_EVENT",
  removeEvent = "REMOVE_EVENT",
  updateDatabase = "UPDATE_DATABASE",
  addUserName = "ADD_USERNAME",
  updateHover = "UPDATE_HOVER",
  removeSelfAvailability = "REMOVE_SELF_AVAILABILITY",
  updateAvailability = "UPDATE_AVAILABILITY",
  loginWithMicrosoft = "LOGIN_WITH_MICROSOFT",
  logoutFromMicrosoft = "LOGOUT_FROM_MICROSOFT",
  loginAsGuest = "LOGIN_AS_GUEST",
}

type ActionAugments = Omit<ActionContext<State, State>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.loadAvailabilities](context: ActionAugments, eventID: any): void;
  [ActionTypes.addEvent](
    context: ActionAugments,
    payload: { unixtime: string; eventID: string; userID: string }
  ): void;
  [ActionTypes.removeEvent](
    context: ActionAugments,
    payload: { unixtime: string; eventID: string; userID: string }
  ): void;
  [ActionTypes.updateDatabase](context: ActionAugments): void;
  [ActionTypes.addUserName](context: ActionAugments, payload: any): void;
  [ActionTypes.updateHover](context: ActionAugments, unixtime: string): void;
  [ActionTypes.updateAvailability](
    context: ActionAugments,
    payload: { unixtime: string; eventID: string; action: 'ADD' | 'REMOVE'; userID: string }
  ): void;
  [ActionTypes.loginWithMicrosoft](
    context: ActionAugments,
    payload: { microsoftUser: any; eventID: string }
  ): Promise<void>;
  [ActionTypes.logoutFromMicrosoft](
    context: ActionAugments,
    payload?: void
  ): void;
  [ActionTypes.loginAsGuest](
    context: ActionAugments,
    payload: { guestName: string; email: string; eventID: string }
  ): Promise<void>;
};

export const actions: ActionTree<State, State> & Actions = {
  async [ActionTypes.loginAsGuest]({ commit, dispatch }, { guestName, email, eventID }) {
    try {
      // Validate email domain
      const domain = email.split('@')[1]?.toLowerCase();
      

      const user = {
        username: `${email}, ${guestName}`,
        name: `${email}, ${guestName}`, 
        email,
        eventID
      };
      
      const newUser = await dispatch(ActionTypes.addUserName, user);
      
      commit(MutationType.SetCurrentUser, {
        ...newUser,
        guestName,
        email,
        username: `${email}, ${guestName}` // Use full email as username
      });
      
      return newUser;
    } catch (error) {
      console.error("Error in loginAsGuest:", error);
      throw error;
    }
  },


  [ActionTypes.loadAvailabilities]: async ({ commit }, eventID) => {
    try {
      const response = await instance.get(`/${eventID}`);
      const eventData = {
        ...response.data,
        id: response.data._id,
        availability: response.data.availability || {},
        users: response.data.users || [],
        selected_dates: response.data.selected_dates || []
      };
      

      commit(MutationType.FetchAvailability, eventData);
      commit(MutationType.RetrieveUserID, eventID);
      
      return eventData;
    } catch (error) {
      console.error('Error loading availabilities:', error);
      throw error;
    }
  },
 

  [ActionTypes.addEvent]({ dispatch }, payload) {
    dispatch(ActionTypes.updateAvailability, { ...payload, action: 'ADD' });
  },
  
  [ActionTypes.removeEvent]({ dispatch }, payload) {
    dispatch(ActionTypes.updateAvailability, { ...payload, action: 'REMOVE' });
  },
  
  [ActionTypes.updateAvailability]({ commit, dispatch }, payload) {
    try {

      // First update local state
      commit(MutationType.UpdateAvailabilityList, payload);
      
      // Then immediately sync with database
      return dispatch(ActionTypes.updateDatabase);
    } catch (error) {
      console.error('Error in updateAvailability:', error);
      throw error;
    }
  },

  async [ActionTypes.updateDatabase]({ state }) {
    try {
      if (!state.eventData?.id) {
        throw new Error('Event ID is missing');
      }
      

      // Construct the correct URL
      const updateUrl = `${state.eventData.id}/update`;
      
      
      const response = await instance.put(updateUrl, {
        availability: state.eventData.availability,
      });
      
     
      return response.data;
    } catch (error) {
      console.error('Error updating database:', error);
      // Type error as AxiosError
      if (error instanceof Error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          console.error('Response Error:', {
            status: axiosError.response.status,
            data: axiosError.response.data,
            headers: axiosError.response.headers
          });
        }
      }
      throw error;
    }
  },


  async [ActionTypes.loginWithMicrosoft]({ commit, dispatch, state }, { microsoftUser, eventID }) {
    try {
      // Check if user already exists with this Microsoft ID
      const existingUser = state.eventData.users?.find(user => 
        'microsoftId' in user && user.microsoftId === microsoftUser.account.homeAccountId
      );

      if (existingUser) {
        // If user exists, just update the current user
        commit(MutationType.SetCurrentUser, {
          ...existingUser,
          username: microsoftUser.account.name, // Changed from using name to username
          email: microsoftUser.account.username,
          microsoftId: microsoftUser.account.homeAccountId
        });
        commit(MutationType.SetUserID, existingUser.id);
        return existingUser;
      }

      // If user doesn't exist, create new user
      const user = {
        username: microsoftUser.account.name,
        email: microsoftUser.account.username,
        eventID
      };
      
      const newUser = await dispatch(ActionTypes.addUserName, user);
      
      commit(MutationType.SetCurrentUser, {
        ...newUser,
        email: microsoftUser.account.username,
        microsoftId: microsoftUser.account.homeAccountId
      });
      
      return newUser;
    } catch (error) {
      console.error("Error in loginWithMicrosoft:", error);
      throw error;
    }
},

async [ActionTypes.addUserName]({ commit, state }, { username, eventID }) {
  try {
  
    const response = await instance.put(`/${eventID}/adduser`, { username });
    
    const userData = response.data.user;

    
    // Only update store if this is a new user
    if (response.data.message === "User added successfully") {
      localStorage.setItem(eventID, userData.id);
      commit(MutationType.AddUserName, userData);
    }
    
    commit(MutationType.SetUserID, userData.id);
    return userData;
  } catch (error) {
    console.error('Error adding user:', error);
    throw error;
  }
},

  [ActionTypes.logoutFromMicrosoft]({ commit }, _payload) {
    commit(MutationType.ClearCurrentUser, undefined);
    localStorage.clear();
  },
  
  [ActionTypes.updateHover]({ commit }, unixtime) {
    commit(MutationType.updateHover, unixtime);
  },
  
  async [ActionTypes.removeSelfAvailability]({ commit, dispatch }) {
    commit(MutationType.removeSelfAvailability);
    await dispatch(ActionTypes.updateDatabase);
  },
};